.autocomplete-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
  width: 325px;
  padding: 5px;

  label {
    width: 100%;
    padding: 0 2px;
    line-height: 30px;
    font-weight: 600;
  }

  input {
    padding: 5px;
    margin: 2px;
    border-radius: 2px;
    border: 1px solid #464555;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
  }

  ul {
    padding: 0;
    margin-top: 10px;
    margin-left: 2px;
    width: 94%;
    min-height: 30px;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    position: absolute;
    top: 80%;
    list-style: none;
    z-index: 2;
    border: 1px solid #aeaeae;
    -webkit-overflow-scrolling: touch;

    li {
      text-align: left;
      padding-left: 5px;
      line-height: 30px;
      cursor: pointer;

      &:hover {
        background-color: grey;
        color: #fff;
      }
    }
  }
}
