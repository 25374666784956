.debtList {
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  & .list-controls {
    display: inline-flex;
    justify-content: flex-end;

    & button {
      display: inline-flex;
    }
  }

  & > .deptItem {
    border: 1px solid #e6e6e6;
    padding: 5px;
    margin: 5px 0;

    &.paye {
      background-color: #7cb34247;
    }

    & > .details {
      font-size: 12px;
      padding: 2px 0;
      min-height: 20px;
      width: 100%;
      line-height: 30px;
      display: inline-flex;
      justify-content: space-between;

      & > .client {
        font-size: 16px;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > .totals {
        font-size: 18px;
      }

      & button {
        font-size: 12px;
      }

      & span {
        padding: 0 10px;

        &:first-child {
          padding-left: 2px;
        }
      }
    }
  }
}

.deptPartsList {
  flex-direction: column;
  border-top: 1px solid #999999;

  & > .deptPart {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
