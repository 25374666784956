.calendar {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  border: 1px solid #e6e6e6;
  font-weight: 600;
  color: #000000;
  z-index: 2;

  & *:focus {
    outline: none;
  }

  & > .disabled-area {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background: #fff;
    opacity: 0.6;
  }

  & > .header {
    display: flex;
    flex-direction: column;
    height: 80px;

    & > .top {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;

      & > .navigation,
      .topLabel {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 28px;
        margin-bottom: 15px;

        & > .label {
          display: inline-flex;
          font-size: 14px;
          padding: 8px;
          cursor: pointer;
        }

        & button {
          display: inline-flex;
          margin: 0;
        }
      }
    }
  }

  & > .body {
    position: relative;
    overflow-y: auto;
    height: 300px;
    margin: 0 5px;
    font-size: 14px;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-thumb {
      background: #0653b6;
    }

    &.datesList {
      display: flex;
      flex-wrap: wrap;
    }

    &.dateEvents {
      margin: 0 14px;
      padding-top: 10px;
    }

    & > .weekItem {
      display: inline-flex;
      flex-direction: row;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #ffffff;

      &.checked {
        border-color: #1c69d4;
      }

      & > .weekChooser {
        width: 10px;
        height: 49px;
        background-color: #e6e6e6;
        cursor: pointer;
      }

      & > .dateItem {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% / 7);
        height: 49px;
        cursor: pointer;

        &.checked {
          background-color: #1c69d4;
          color: #ffffff;
        }

        & > .contentWrapper {
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: column;

          & > .date {
            margin: 5px;
            text-align: center;
          }

          & > .activityWarn {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #1c69d4;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 5px);
          }
        }
      }
    }

    & > .monthItem {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: calc(100% / 3);
      height: 50px;
      cursor: pointer;
      margin-top: 3px;
    }
  }
}

.icon {
  &::before {
    content: '';
    display: inline-block;
    width: 28px;
    height: 28px;
    background-size: 28px;
  }

  &.nav_next:before {
    background-image: url('./assets/BMW-pos_nav_next.png');
  }

  &.nav_prev:before {
    background-image: url('./assets/BMW-pos_nav_previous.png');
  }
}
