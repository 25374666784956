.chantiersListTab {
  flex: 1;
}

.chantiersListMap {
  display: flex;
  flex: 1;

  & .markerInfoWindow {
    display: flex;
    flex-direction: column;
  }
}

.chantiersList {
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  & > .chantier {
    border: 1px solid #e6e6e6;
    padding: 5px;
    margin: 5px 0;

    & > .client {
      font-size: 16px;
      padding-bottom: 5px;
    }

    & > .details {
      font-size: 12px;
      padding: 2px 0;
      height: 30px;
      width: 100%;
      line-height: 30px;
      display: inline-flex;
      justify-content: space-between;

      & button {
        font-size: 12px;
      }

      & span {
        padding: 0 10px;

        &:first-child {
          padding-left: 2px;
        }
      }
    }
  }
}
