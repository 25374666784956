.react-datepicker,
.react-datepicker__month-container {
  width: 100%;
}

.eventsListTab {
  display: inline-flex;
  padding: 0 10px;
  margin: 0;
  width: calc(100% - 300px);
  height: 70vh;
  overflow: hidden;
}

.locationInputWrap {
  & > input {
    width: 84%;
  }

  & > select {
    width: calc(16% - 8px);
  }
}

.eventsDeshboard {
  display: flex;
  flex-direction: column;

  & > .deshboardHeader {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    & > .flexEnd {
      display: inline-flex;
      flex-direction: row;
    }
  }

  & > .deshboard {
    display: inline-flex;
    flex-direction: row;

    & > .rightSide {
      padding-top: 25px;
      width: 300px;
    }
  }

  &.mobile {
    & > .deshboardHeader {
      padding: 10px 8px;

      & > .periodLabel {
        padding-left: 5px;
        line-height: 28px;
        font-size: 12px;
      }

      & > .flexEnd {
        width: 60%;
        flex-direction: column;

        & button {
          font-size: 12px;
        }
      }
    }

    & > .deshboard {
      flex-direction: column;

      & > .extendedEventsList,
      .calendar {
        min-height: 200px;
        margin-bottom: 10px;
        width: calc(100% - 20px);
      }
    }
  }
}

.inputForm.modal {
  width: 335px;
  justify-content: center;
}

.driversList {
  display: inline-flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;

  & > .driverItem {
    padding: 10px 5px;
    display: inline-flex;
    justify-content: space-between;
  }
}

.statusChange {
  width: 80px;
  font-size: 12px;

  &.pansante_status {
    background-color: rgb(138, 13, 131);
  }

  &.annulee_status {
    background-color: maroon;
  }
}
.editFormItem {
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 10px;
  background: #d9d9d9;
  width: 103%;
  padding-bottom: 10px;

  & > .formFooter {
    flex-direction: row;
  }
}
