@import './variables.scss';
@import './form.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  background-color: $body-bg;
  overflow-x: hidden;
  scroll-behavior: smooth;

  button,
  input,
  select,
  textarea {
    &:focus {
      outline: none;
    }
  }

  p {
    margin: 0;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  img {
    max-width: 100% !important;
    object-fit: contain;
  }

  a {
    text-decoration: none;
    color: $text-color;
  }
}

#__next {
  display: flex;
  justify-content: center;
}

.link {
  color: $link-color;
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
  margin-bottom: 10px;

  & .order_form_input {
    margin: 5px 0;
  }
}

.react-datepicker__month-container {
  width: calc(100% - 86px) !important;
}

.buttonsSet {
  display: inline-flex;
  margin-left: 10px;

  & > .cancel {
    background-color: #757575;
  }

  & > .confirm {
    background-color: #e91e63;
  }
}

.button {
  border-radius: 2px;
  border: none;
  color: $primary-white;
  background-color: $text-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  text-align: center;
  cursor: pointer;
  padding: 6px;
  margin: 2px;
  transition: 0.5s;

  &.blank {
    background-color: transparent;
    border: 1px solid $primary-white;
  }

  &.icon-btn {
    padding: 0;
    border: 0;
  }

  &:disabled {
    background-color: #999;
    color: #ffffff;
  }

  &.succes {
    background-color: green;
  }

  &.warn {
    background-color: indianred;
  }

  &.warn_secondary {
    background-color: rgb(100, 92, 205);
  }
}

.container {
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  width: calc(25% - 10px);
  margin: 5px;
  border-radius: 2px;
  background-color: $primary-white;
  box-sizing: border-box;

  &.transparent {
    background-color: transparent;
  }

  &.fullWidth {
    width: calc(100% - 10px);
  }

  &.box-34 {
    width: calc(75% - 10px);
  }

  &.box-12 {
    width: calc(50% - 10px);
  }

  &.items-col {
    flex-direction: column;
  }

  &.items-row {
    flex-direction: row;
  }
}

.payment-page-wrap {
  display: flex;
  flex-direction: column;
  text-align: center;

  & .logo {
    height: 40px;
    margin: 50px;
  }
}