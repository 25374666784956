@import '../../../style/variables.scss';

.invoiceGenerator {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px 0;
  width: 280px;
  min-height: 150px;
  border: 1px solid #e6e6e6;
  font-weight: 600;
  color: #000000;
  z-index: 2;

  & > .header {
    height: 30px;

    & button {
      font-size: 12px;
      height: 30px;
    }
  }

  & > .invoiceItems {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    min-height: 150px;
    font-size: 12px;
    text-align: center;

    & .success-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: green;
      color: #fff;
      font-size: 12px;
    }

    & > .invoiceItem {
      position: relative;
      border: 1px solid #e6e6e6;
      text-align: left;
      padding: 5px;
      margin: 5px 0;

      & > .closer {
        position: absolute;
        right: 10px;
        height: 10px;
        width: 10px;
        top: 5px;
        right: 10px;

        &:before {
          content: '+';
          position: absolute;
          font-size: 20px;
          transform: rotate(45deg);
          cursor: pointer;
        }
      }

      & > .client {
        font-size: 16px;
        padding-bottom: 5px;
      }

      & > .details {
        font-size: 12px;
        padding: 2px 0;
      }
    }
  }
}
