.receiptsList {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  & .receiptItem {
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    cursor: pointer;
    padding: 5px;
    margin: 5px 0;
    border-bottom: 1px solid #777777;
    justify-content: space-between;
    align-items: center;
  }
}

.capture-totals {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 5px;
  box-sizing: border-box;

  & .info-row {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.extendedEventsList {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 68vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  & > .dayContainer {
    margin-top: 50px;
    height: initial;
    min-height: min-content;
    border-left: 2px solid #4a4a4a;
    color: #4a4a4a;
    padding-left: 5px;
    display: inline-flex;
    flex-direction: column;

    &:first-child {
      margin-top: 10px;
    }

    & > .date {
      display: inline-flex;
      font-weight: bold;
      font-size: 18px;
      padding-bottom: 10px;
    }

    & > .event {
      display: inline-flex;
      flex-direction: column;
      border: 1px solid #e6e6e6;
      margin-bottom: 10px;
      border-radius: 2px;
      height: 130px;
      padding: 5px;

      &:hover {
        border-color: #7cb342;
      }

      &.particular {
        background-color: #fff59d;
      }

      &.cabinet {
        background-color: #ffc0a7;
      }

      &.withDriver {
        background-color: #7cb34247;
      }

      & > .eventHeader {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;

        & > .label {
          font-size: 16px;
          line-height: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > .controls {
          display: inline-flex;
          flex-direction: row;
          height: 30px;

          button {
            font-size: 12px;
          }
        }
      }

      & > .footer {
        display: inline-flex;
        flex-direction: column;
        font-size: 12px;
        padding-top: 5px;

        & > .comments {
          margin: 10px 0;
        }

        & > .details {
          display: inline-flex;
          flex-direction: row;
          border: none;
          margin: 0;
          padding: 0;

          & div:not(.status) {
            padding: 0 3px;
            margin-left: 5px;
          }

          & > .warn {
            color: brown;
            font-weight: bold;
          }

          & > .trashType {
            font-weight: bold;

            &.Gravats {
              color: #d1130c;
            }

            &.Dechets {
              color: #2715c4;
            }

            &.Ferraille {
              color: #c00b8a;
            }

            &.Vegetaux {
              color: #509605;
            }

            &.Bois {
              color: #dbb300;
            }
          }

          & > .since.includeHour {
            font-weight: bold;
            color: brown;
          }

          & > .status {
            background-color: #b3b3b3;
            border-radius: 50%;
            margin: 1.5px;
            padding: 0;
            margin-right: 3px;
            border: none;
            width: 9px;
            height: 9px;
            min-width: 9px;

            &.pending {
              background-color: indianred;
            }

            &.active {
              background-color: green;
            }

            &.particular {
              background-color: #d6c100;
            }

            &.cabinet {
              background-color: #ff6b31;
            }
          }
        }

        & > .location {
          margin: 0;
          padding: 0;
          margin-bottom: 5px;
          border-right: none;
          background-color: transparent;
          border: none;
          resize: none;
          cursor: pointer;
          font-family: 'Open Sans', sans-serif;
        }

        & .phoneNumbers {
          display: inline-flex;
          flex-direction: row;

          .phoneLink {
            color: #337ab7;
            margin: 3px 0;
            margin-right: 15px;
            width: fit-content;

            &.own {
              color: darkorange;
            }
          }
        }
      }
    }
  }
}
