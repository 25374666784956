@import '../../../style/variables.scss';

#root {
  display: flex;
  justify-content: center;

  & > .wrap {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    display: inline-flex;
    flex-direction: column;
    max-width: 1800px;

    &.noMargin {
      max-width: 100vw;
      height: 100vh;
      overflow: hidden;

      &.mobile {
        height: 100%;
      }

      & > main {
        padding: 0;
        height: calc(100vh - 110px);
      }
    }
  }
}

.header {
  display: inline-flex;
  position: relative;
  background-color: $primary-white;
  min-height: 60px;
  height: 60px;
  margin-bottom: 5px;

  & nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    height: 35px;
    width: calc(100% - 20px);
    background-color: $primary-white;
    margin: 0 10px;

    & div {
      display: inline-flex;

      & > .logo {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;
        margin-right: 30px;
        padding-left: 10px;
        font-weight: bold;
        font-size: 30px;
      }

      & > .navLink:not(.logo) {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background-color: $primary-white;
        border-bottom: 2px solid $primary-white;
        text-align: center;
        font-family: sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: $text-color;
        cursor: pointer;

        &:hover,
        &.active {
          border-color: $brand-succes;
        }
      }
    }
  }
}

main {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  background-color: $primary-white;
  padding: 5px 10px 50px 10px;

  &.mobile {
    height: 100% !important;
  }
}

footer {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  font-size: 12px;
  color: #666666;
  background-color: $primary-white;
  margin-top: 5px;
  padding: 5px;
  height: 50px;

  &.mobile {
    font-size: 8px;
  }

  & div {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;

    & p {
      padding: 2px 5px;
    }
  }
}

.userName {
  font-size: 14px;
  line-height: 35px;
  padding-right: 20px;
}
