@import '../../../style/variables.scss';

.tabs > .tabsContent {
  margin: 0;
  width: 100%;
  height: 68vh;
  display: inline-flex;
}

.tabs > .navigation {
  display: flex;
  width: 100%;
  border-bottom: 2px solid $brand-primary;
  margin-bottom: 5px;

  & > .tab {
    width: calc(100% / 2);
    border: 0;
    text-align: center;
    padding: 5px 10px;
    background-color: $primary-white;
    color: $text-color;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.active {
      background-color: $brand-primary;
      color: $primary-white;
    }
  }
}
